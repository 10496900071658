@use 'colors';
@use 'mixins';
@use 'gaps';

.form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  form {
    width: 100%;
  }

  & * {
    box-sizing: border-box;
  }

  &__section {
    width: 50%;

    @media screen and (max-width: gaps.$mobile-width) {
      width: auto;
    }

    &-wrapper {
      display: flex;

      @media screen and (max-width: gaps.$mobile-width) {
        flex-direction: column;
      }
    }

    &-inner {
      display: flex;
      align-items: baseline;
    }

    &--margin-right {
      margin-right: 100px;

      @media screen and (max-width: gaps.$mobile-width) {
        margin-right: 0;
      }
    }
  }

  &__input {
    &-text {
      width: 100%;
      height: 42px;
      border-radius: 24px;
      padding: 0 16px;
      border: none;
      margin-bottom: 16px;

      &:focus,
      &:focus-visible {
        outline: none;
        border: none;
        border: 1px solid colors.$blue;
      }
    }

    &-sum {
      width: 100%;
      display: block;
      margin: 32px 0 0;
      padding: 0 16px;
      height: 42px;
      border: none;
      border-bottom: 1px solid colors.$grey-dark;
      background-color: transparent;

      &:focus,
      &:focus-visible {
        outline: none;
        border: none;
        border-left: 2px solid colors.$blue;
        border-bottom: 1px solid colors.$grey-dark;
      }
    }

    &-radio {
      margin-right: 12px;
    }
  }

  &__payment-method-btn {
    @include mixins.flex-line;
    height: 50px;
    padding: 8px 16px;
    margin-right: 12px;
    background-color: white;
    border: none;
    border-radius: 20px;

    & .img-paypal {
      width: 69px;
      height: 39px;
    }

    & .img-stripe {
      width: 71px;
      height: 33px;
    }

    &:hover {
      background-color: colors.$white-grey;
    }

    &:active {
      transform: translateY(4px);
    }
  }

  label {
    margin-right: 42px;
  }

  &__description {
    margin: 32px 0 16px;
  }

  &__success {
    margin: 24px 0;
    background-color: white;
    padding: 16px;
    border-radius: 8px;
  }
}

.stripe-form {
  margin-top: 40px;
  
  @media screen and (max-width: gaps.$mobile-width) {
    width: 100%;
  }
}

$blue: #397CE0;
$blue-light: #BAD0F1;

$grey: #ECEEF3;
$grey-light: #ECEEF3;
$grey-dark: #C4C4C4;
$grey-text: #848484;

$black: #12305E;

$white: #F8F8F8;
$white-grey: #e8e8e8;

@use 'colors';
@use 'fonts';
@use 'gaps';
@use 'mixins';

.header {
  height: 100px;

  @include mixins.flex-line;
  justify-content: space-between;
  padding: gaps.$side-gap;
  background-color: colors.$blue;
  color: colors.$blue-light;
  font-weight: 400;

  &__logo {
    max-width: 177px;
    margin-right: 50px;
    margin-top: -8px;
  }

  &__menu {
    @include mixins.flex-line;

    &-item {
      margin-right: 40px;
      color: colors.$blue-light;
      text-decoration: none;

      &--active {
        color: colors.$grey-light;
        font-weight: 700;
      }
    }

    &-wrapper--mobile {
      display: none;

      .header__menu {
        position: absolute;
        top: 75px;
        padding: 16px 24px;
        background-color: white;
        @include mixins.flex-column-start;
        border-radius: 16px;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
        z-index: 1;

        &-btn {
          font-size: 20px;
          font-weight: 700;
          color: #ECEEF3;
        }

        &-item {
          color: colors.$black;
          font-weight: 600;
          font-size: 16px;
          margin: 8px 0;
        }
      }

      @media screen and (max-width: gaps.$tablet-width) {
        display: block;
      }
    }

    &-wrapper--desktop {
      @media screen and (max-width: gaps.$tablet-width) {
        display: none;
      }
    }
  }

  &__lngs {
    @include mixins.flex-line;
  }

  &__lng {
    font-size: fonts.$size-default;
    color: colors.$blue-light;
    font-weight: 400;

    &--active {
      color: colors.$grey-light;
      font-weight: 700;
    }
  }
}

.footer {
  .header__menu {
    @include mixins.flex-column-start;
  }
}
@use 'styles/header.scss';
@use 'styles/form.scss';
@use 'styles/_mixins.scss';
@use 'styles/_gaps.scss';
@use 'styles/_fonts.scss';
@use 'styles/_colors.scss';

@import-normalize; /* bring in normalize.css styles */
@import url(http://fonts.googleapis.com/css?family=Open+Sans:400,600,700);

body {
  font-family: 'Open Sans', sans-serif;
  font-size: fonts.$size-default;
}

.btn {
  background: none;
  border: none;
  cursor: pointer;
}

.section {
  position: relative;
  padding: gaps.$section-gap;
  display: flex;
  color: colors.$black;
  font-weight: 600;

  &__inner {
    @include mixins.flex-column-sp-between;
    width: 100%;

    &--img-wrapper {
      @media screen and (max-width: gaps.$tablet-width) {
        width: 40%;
        margin-bottom: 30px;
      }

      @media screen and (max-width: gaps.$mobile-width) {
        width: auto;
      }
    }

    &--half {
      width: 50%;
    }

    &:not(:last-child) {
      margin-right: gaps.$section-inner-margin-right;
    }
  }

  &__title {
    font-size: 42px;
    line-height: 52px;
    margin-bottom: 32px;

    @media screen and (max-width: gaps.$mobile-width) {
      font-size: 26px;
      line-height: 38px;
    }

    &--small {
      font-size: 32px;
      margin-top: 40px;
    }

    &--bold {
      font-weight: 700;
    }
  }

  &__description {
    line-height: 24px;
    margin-bottom: 24px;

    &--small {
      font-size: fonts.$size-descr-small;
      font-weight: 400;
      line-height: 30px;
    }

    &--no-margin {
      margin: 0;
    }
  }

  &__error {
    margin: 16px 0 24px;
    color: red;
    font-size: 14px;
  }

  &__btn {
    align-self: flex-start;
    padding: gaps.$btn-big-padding;
    background-color: colors.$blue;
    color: colors.$white;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    border-radius: 26px;

    &--pay {
      margin-top: 32px;

      &:disabled,
      &[disabled] {
        background-color: white;
        border: 1px solid colors.$grey-dark;
        color: colors.$grey-text;
        cursor: not-allowed;
      }
    }

    &--small {
      padding: 8px 16px;
      margin: 24px 24px 0 0;
      font-size: 14px;
      font-weight: 600;
    }

  }

  &__list {
    @include mixins.flex-line;
    justify-content: space-between;

    &-item {
      width: 178px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      img {
        margin-bottom: 32px;

        @media screen and (max-width: gaps.$mobile-width) {
          margin-bottom: 12px;
        }
      }

      @media screen and (max-width: gaps.$mobile-width) {
        margin-bottom: 40px;
      }
    }

    @media screen and (max-width: gaps.$mobile-width) {
      flex-direction: column;
    }
  }

  &__sub {
    @include mixins.flex-line-start;
    justify-content: space-between;
    
    .section__description {
      margin: 32px 0 20px;
    }

    @media screen and (max-width: gaps.$mobile-width) {
      flex-direction: column;
    }
  }

  &--main {
    @media screen and (max-width: gaps.$mobile-width) {
      flex-direction: column-reverse;
    }
  }

  &--main img {
    max-width: 525px;
    height: auto;
    width: 100%;
    display: block;

    @media screen and (max-width: gaps.$mobile-width) {
      max-width: none;
    }
  }

  &--who-we-are {
    background-color: colors.$grey;
  }

  &--pay {
    flex-direction: column;
    background-color: colors.$grey;

    .section__inner:not(:last-child) {
      margin-right: gaps.$section-inner-margin-right-XL;
    }
  }
}

.footer {
  background-color: colors.$blue;
  color: colors.$blue-light;
  font-weight: 400;

  .header__menu-item {
    margin-bottom: 16px;
  }

  &__menu-item {
    margin-bottom: 16px;

    &--title {
      color: #ECEEF3;
      font-weight: 700;
    }
  }

  &__socials-wrapper {
    display: flex;
  }

  &__social {
    display: flex;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: white;
    border-radius: 50%;
    margin-right: 16px;

    &:hover {
      background-color: colors.$blue-light;
    }
  
    &--fb {
      margin-top: 8px;
    }

    &--inst {
      width: 20px;
    }
  }

  @media screen and (max-width: gaps.$mobile-width) {
    flex-direction: column;
  }
}
